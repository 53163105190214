import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import IntegrationGithub from '../components/PersonalIntegrations/gitHubPage'
import { Helmet } from "react-helmet";

// const content = `Record working hours and control time you spend on developing in Github with DueFocus time tracking app`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const GitHub = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Github Integration with DueFocus Time Tracking Software</title>
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/time-tracking-github/" />
    </Helmet>
    <Header />
    <IntegrationGithub />
    <Footer />
  </>
)

export default GitHub