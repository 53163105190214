
import React from "react";
import FirstBlock from './FirstBlock/index'
import SecondBlock from './SecondBlock/index'
import GetStarted from '../Integrations/FormBlock/getStarted'
import ThirdBlock from './ThirdBlock/githubTable'
import FourthBlock from './FourthBlock/index'
// import FifthBlock from './FifthBlock/index'
import SixthBlock from './SixthBlock/index'
import EmptyBlock from '../Header/emptyBlock'


import logoBottom from '../img/icons/Logo_icon_duefocus.svg'
import logoBottomAsana from '../img/icons/icon_duefocus_gitHub.svg'
import logoAsana from '../img/logoGitHub.png'
import duefocusImg from '../img/Illustration_Github.png'
import MobileLogo from '../img/icons/personal_integration/MobileLogos/GitHub.svg'

import coldStart from '../img/coldStartIntegrations/Github.png'
import coldStartMini from '../img/coldStartIntegrations/Github_mini.svg'

import asanaTable from '../img/icons/integrations/Timetrackers/Logo_tracker_github.svg'

import asana from '../img/IntegrationsScreenshots/Teammates-Github.png'
import asana2 from '../img/IntegrationsScreenshots/Report-Github.png'

const headerText = 'Time Tracking In Github, Just The Way You Want It';
const description = 'Improve Github with the most powerful time tracking software. DueFocus is fully friendly with your project management tool';
const buttonName = 'Import Your Github Team';

const bottomText = 'Save Your Time Spent on Github Issues';
const bottomDescription = "You don't need to switch between applications anymore. Using DueFocus desktop app, Google Chrome Extension or directly from the task page with just one-click you can create, add and launch GitHub tasks. Track time in GitHub according to your plan or turn on an 'overspent mode' and proceed time tracking despite the end of estimation. All you need is just to report how much time you spent afterwards. Moreover try our unique Cold Start feature that allows to track time and reassign it to the tasks later.";

// Second Block
const text = 'Watch The Activity Of Your Teammates in Real Time';
const description1 = "Invite your GitHub colleagues into DueFocus and become teammates with GitHub time tracking integration. Use Team Pulse feature to analyze and monitor their activity in real-time mode. It helps to see how much and what for time was spent.";
const text2 = 'Observe Reported Data and Create Invoices for Your Clients';
const description2 = "Observe reported data of all users and projects you belong to and export it to CSV, XLS or PDF file for any period of time. GitHub integration allows to create invoices and send them easily to your clients right in DueFocus without using third-party invoicing services.";




export default class integrationGitHub extends React.Component {
  
  render() {
    return (
   <>
    <EmptyBlock />
     <FirstBlock
     MobileLogo={MobileLogo} 
     logo={logoAsana} 
     duefocus={duefocusImg}
     header={headerText}
     description={description}
     buttonName={buttonName}
     bottomText={bottomText}
     bottomDescription={bottomDescription}
     />
     <SecondBlock
     imgAsana={asana}
     imgAsana2={asana2}
     text={text}
     description1={description1}
     text2={text2}
     description2={description2}
     />
     <ThirdBlock logo={asanaTable}/>
     <GetStarted logo={logoBottom}/>
     <FourthBlock coldStart={coldStart} coldStartMini={coldStartMini}/>
     {/* <FifthBlock /> */}
     <SixthBlock />
     <GetStarted logo={logoBottomAsana}/>
   </>
    )
  }
}



